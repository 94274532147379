import React from "react"
import Layout from "../components/Layout"
import Navigation from "../components/Navigation"
import { navigate } from "gatsby"
import * as styles from "../assets/css/indexNavigation.module.css"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"

const Card1 = ({ data }) => {
  const { image, back } = data
  return (
    <Layout>
      <div className={styles.backResponsive}>
        <div
          className={styles.back}
          onClick={() => navigate(-1)}
          onKeyDown={() => navigate(-1)}
          role="button"
          tabIndex="0"
        >
          <GatsbyImage image={getImage(back)} title={back.title} alt="Zurück" />
        </div>
      </div>

      <div className={styles.neurologeHeader}>Wann zum Neurologen?</div>
      <div className={styles.neurologeContentContainer}>
        <div className={styles.neurologeText1}>
          <p>
            Der Facharzt für Neurologie bietet Hilfe bei Erkrankungen, die das
            zentrale (Gehirn und Rückenmark) oder periphere (äußere) sowie das
            vegetative Nervensystem betreffen. Zudem ist er der Fachmann für
            erbliche oder erworbene Muskelerkrankungen.
          </p>
        </div>
      </div>
      <div className={styles.neurologeContentImageContainer}>
        <div className={styles.neurologeContentImage}>
          <GatsbyImage
            image={getImage(image)}
            title={image.title}
            alt="Computertomographie"
          />
        </div>
      </div>

      <div className={styles.neurologeContentContainer2}>
        <div className={styles.neurologeText2}>
          <p>
            Gehirn und Rückenmark bilden das zentrale Nervensystem. Häufige
            Erkrankungen bzw. Funktionsstörungen dieser Organe sind
            Schlaganfall, Multiple Sklerose, Parkinson- Erkrankung, Epilepsie,
            Alzheimer und anderer Demenzformen, sowie Kopfschmerz.
          </p>
          <p>
            Ein häufiger Zustand ist auch der Schwindel und die
            Gangunsicherheit, deren Ursache in einer Störung der zentralen
            Nervensystems liegen können.
          </p>
          <p>
            Das periphere Nervensystem umfasst die Nerven beginnend von den
            Nervenwurzeln, die vom Rückenmark entspringen, und sich dann zu
            Nervensträngen vereinen, die an Arm und Bein die jeweiligen
            Hauptnerven bilden, bis zu den feinen Verästelungen in Haut und
            Muskeln. Die Nervenwurzeln können bei Wirbelsäulenveränderungen wie
            dem Bandscheibenvorfall betroffen sein („Ischias“), die Nerven in
            Armen und Beinen bei allgemeinen Stoffwechselstörungen wie Diabetes
            („Polyneuropathien“) oder im Rahmen von örtlichen
            Nervendruckschäden, wie zum Beispiel beim relativ häufigen
            Carpaltunnelsyndrom.
          </p>
          <p>
            {" "}
            Auch Abklärung und Behandlung von Schmerzen, wie insbesondere
            Kopfschmerzen, Wirbelsäulenschmerzen, Nervenwurzelschmerzen sowie
            anderweitige Nervenschmerzen fallen in das Fachgebiet des
            Neurologen.
          </p>
        </div>
      </div>

      <Navigation></Navigation>
    </Layout>
  )
}

export default Card1

export const pageQuery = graphql`
  query WhenQuery {
    image: contentfulAsset(contentful_id: { eq: "6jzC7yes2OcNFiU6MjX1p2" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    back: contentfulAsset(contentful_id: { eq: "6fycJDkI3crd9QifanGGwI" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
  }
`
